<template>
  <footer v-show="isFooterBarVisible" class="bg-white py-3 px-6">
    <level>
      <div>
        made with ❤️ by <a href="https://hive.blog/@balaz">@balaz</a>
      </div>
    </level>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level'

export default {
  name: 'FooterBar',
  components: {
    Level
  },
  setup () {
    const store = useStore()

    const year = computed(() => new Date().getFullYear())

    const isFooterBarVisible = computed(() => !store.state.isFormScreen)

    return {
      year,
      isFooterBarVisible
    }
  }
}
</script>
