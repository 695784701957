import {
  mdiTable
} from '@mdi/js'

export default [
  'Query',
  [
    {
      to: '/totals',
      label: 'Totals',
      icon: mdiTable,
      updateMark: true
    },
    {
      to: '/topAccounts',
      label: 'Top Accounts',
      icon: mdiTable,
      updateMark: true
    }
  ]
]
