import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,
    table1: []
  },
  mutations: {

    table1Data(state, data) {
      state.table1 = data
    }
  },
  actions: {
    asideMobileToggle({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.getElementById('app').classList[isShow ? 'add' : 'remove']('ml-60')

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped')

      commit('basic', {
        key: 'isAsideMobileExpanded',
        value: isShow
      })
    },
    asideLgToggle({ commit, state }, payload = null) {
      commit('basic', { key: 'isAsideLgActive', value: payload !== null ? payload : !state.isAsideLgActive })
    },
    formScreenToggle({ commit, state }, value) {
      commit('basic', { key: 'isFormScreen', value })

      document.documentElement.classList[value ? 'add' : 'remove']('form-screen')
    },
    async totalHiveTransferred({ commit }) {
      const response = await axios.get('https://api.hivesql.hivedata.live/totalHiveSentUTOPIS')
      if (response.data.length > 0) {
        commit('table1Data', response.data)
      }
    }
  },
  modules: {
  }
})
